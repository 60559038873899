<template>
  <v-row justify="end">
    <v-dialog
      v-model="isVisibleDialog"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$admin.can('order-create')"
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          {{ $t("add_new_order") }}
        </v-btn>
      </template>
      <v-form
        ref="form"
        v-model="is_valid"
        lazy-validation
      >
        <v-card>
          <v-card-title class="pb-4">
            <span class="text-h3">{{ $t("order") }}</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container v-if="formErrors && !(formErrors.length == 0)">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-alert
                        type="error"
                        dense
                        outlined
                        class="pa-2 mb-0"
                      >
                        <ul>
                          <li
                            v-for="(err, key) in formErrors"
                            :key="key"
                          >
                            {{ $t(key) }} : {{ err[0] }}
                          </li>
                        </ul>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
            <v-container>
              <v-card elevation="5">
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-select
                        dense
                        hide-details="auto"
                        outlined
                        :label="$t('hub_*')"
                        v-model="editedOrder.hub_id"
                        :items="listHubs"
                        item-text="name"
                        item-value="id"
                        :rules="[rules.required]"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-select
                        dense
                        hide-details="auto"
                        outlined
                        :label="$t('payment_method_*')"
                        v-model="editedOrder.payment_method_id"
                        :items="paymentMethods"
                        item-text="name"
                        item-value="id"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        dense
                        hide-details="auto"
                        outlined
                        :label="$t('client_name_*')"
                        v-model="editedOrder.client_name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        dense
                        hide-details="auto"
                        outlined
                        :label="$t('client_phone_*')"
                        v-model="editedOrder.client_phone"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        dense
                        outlined
                        :label="$t('tracking_number_ext_*')"
                        v-model="editedOrder.tracking_number_ext"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-combobox
                        dense
                        outlined
                        :label="$t('order_origin_*')"
                        v-model="editedOrder.order_origin_name"
                        :items="orderOriginsNames"
                      ></v-combobox>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <v-text-field
                        dense
                        hide-details="auto"
                        outlined
                        :label="$t('shipping_cost')"
                        type="number"
                        v-model="editedOrder.shipping_cost"
                        :rules="[]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <v-text-field
                        dense
                        hide-details="auto"
                        outlined
                        :label="$t('service_cost')"
                        type="number"
                        v-model="editedOrder.service_cost"
                        :rules="[]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <v-text-field
                        dense
                        hide-details="auto"
                        outlined
                        :label="$t('coupon')"
                        type="number"
                        v-model="editedOrder.coupon_amount"
                        :rules="[]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <v-text-field
                        dense
                        hide-details="auto"
                        disabled
                        outlined
                        :label="$t('reduction')"
                        type="number"
                        v-model="editedOrder.reduction"
                        :rules="[]"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-select
                        dense
                        outlined
                        :label="$t('type_*')"
                        v-model="editedOrder.type"
                        :items="ordersTypes"
                        item-text="name"
                        item-value="value"
                        :rules="[rules.required]"
                        @change="updateOrderPrice(editedOrder.type)"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>

            <v-container>
              <v-card elevation="5">
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-autocomplete
                        dense
                        hide-details
                        outlined
                        :label="$t('name')"
                        v-model="orderProductToAdd.id"
                        :items="listProducts"
                        item-text="name_marketing"
                        item-value="id"
                        :search-input.sync="search_product_input"
                        :loading="isLoadingProducts"
                        no-filter
                        @keyup.enter="addProductByEnterKey"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                    >
                      <v-text-field
                        dense
                        hide-details="auto"
                        outlined
                        :label="$t('quantity')"
                        type="number"
                        v-model="orderProductToAdd.qte_in_order"
                        :rules="[rules.quantity]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="2"
                      md="2"
                    >
                      <v-btn
                        block
                        outlined
                        color="primary"
                        @click="addProduct(orderProductToAdd)"
                        :disabled="!is_valid"
                        >{{ $t("validate") }}</v-btn
                      >
                    </v-col>

                    <v-col cols="12">
                      <div v-if="orderProducts">
                        <v-data-table
                          :item-class="itemBG"
                          :headers="orderProductsHeaders"
                          :items="orderProducts"
                          hide-default-footer
                          dense
                          :items-per-page="-1"
                        >
                          <template
                            v-if="!is_new"
                            v-slot:item.order_price_ht="{ item }"
                          >
                            <v-text-field
                              class="my-1"
                              dense
                              hide-details="auto"
                              outlined
                              type="number"
                              v-model="item.order_price_ht"
                              :rules="[
                                (v) =>
                                  item.order_price_ht - item.purchase_price_ht >
                                    0 || $t('products.selling_price_condition'),
                              ]"
                            ></v-text-field>
                          </template>
                          <template v-slot:item.qte_in_order="{ item }">
                            <v-text-field
                              class="my-1"
                              hide-details="auto"
                              outlined
                              dense
                              type="number"
                              v-model="item.qte_in_order"
                              :rules="[
                                rules.min_quantity(0),
                                rules.max_quantity(
                                  item.qte_in_hub || getQteInHub(item.id)
                                ),
                              ]"
                              tabindex="-1"
                            ></v-text-field>
                            <!-- ,rules.max_quantity(item.qte_in_hub) !-->
                          </template>
                          <template v-slot:item.discount="{ item }">
                            <v-text-field
                              v-if="!item?.promotion"
                              dense
                              hide-details="auto"
                              outlined
                              type="number"
                              v-model="item.discount"
                              :rules="[rules.max_quantity(100)]"
                            ></v-text-field>
                            <span v-else>{{ item.promotion.discount }}</span>
                          </template>
                          <template v-slot:item.total="{ item }">
                            {{ calcProductPrice(item) }}
                          </template>
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                              color="red"
                              @click="removeProduct(item)"
                              tabindex="-1"
                            >
                              mdi-close
                            </v-icon>
                          </template>
                          <template v-slot:no-data>
                            {{ $t("empty") }}
                          </template>
                        </v-data-table>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
            <v-container>
              <v-card elevation="5">
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      {{ $t("expected_items_total") }}
                    </v-col>
                    <v-col
                      class="text-right"
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      {{ editedOrder.expected_items_total || "-" }}
                      {{ user.currency }}
                    </v-col>
                  </v-row>
                  <hr class="my-2" />
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      {{ $t("items_total") }}
                    </v-col>
                    <v-col
                      class="text-right"
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      {{ totalItemsPrice }} {{ user.currency }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="saveForm(editedOrder)"
              :disabled="!is_valid"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
  mixins: [validationRules],

  computed: {
    ...mapGetters({
      isLoadingProducts: "products/isLoadingProducts",

      listProducts: "products/listProducts",
      orderStatuses: "orderStatuses/listStatuses",
      paymentMethods: "paymentMethods/listMethods",
      listHubs: "hubs/activeHubs",
      orderOriginsNames: "orderOrigins/getOrderOriginsNames",
      ordersHubId: "orders/getHubID",
      ordersTypes: "orders/getTypes",
      user: "users/editedUser",
    }),
    isVisibleDialog: {
      get() {
        return this.$store.state.orders.isVisibleDialog;
      },
      set(value) {
        this.$store.commit("orders/IS_VISIBLE_DIALOG", value);
      },
    },
    editedOrder: {
      get() {
        return (
          this.$store.state.orders.editedOrder ?? {
            hub_id: this.getMainHub(this.listHubs).id,
          }
        );
      },
      set(value) {
        this.$store.commit("orders/EDITED_ORDER", value);
      },
    },
    orderProducts: {
      get() {
        return this.$store.state.orders.editedOrder.orderItems || [];
      },
      set(value) {
        this.$store.commit("orders/ORDER_ITEMS", value);
      },
    },

    totalItemsPrice() {
      const sum = this.orderProducts
        .map((item) => {
          return parseFloat(this.calcProductPrice(item));
        })
        .reduce((a, b) => a + b, 0);
      return sum < 0 ? 0 : sum.toFixed(2);
    },

    totalItemsDiscount() {
      const sum = this.orderProducts
        .map((item) => {
          return this.calcProductDiscount(item);
        })
        .reduce((a, b) => a + b, 0);
      return sum;
    },

    hub_id() {
      return this.editedOrder.hub_id;
    },

    is_new() {
      return this.editedOrder.id ? false : true;
    },

    orderProductsHeaders() {
      return [
        {
          text: this.$t("name"),
          align: "start",
          sortable: false,
          value: "name_marketing",
          width: "30%",
        },

        {
          text: this.$t("price_ht"),
          value: "order_price_ht",
          align: "center",
          sortable: false,
          width: "15%",
        },
        {
          text: this.$t("tva"),
          value: "order_tva",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("purchase_price"),
          value: "purchase_price_ht",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("quantity"),
          value: "qte_in_order",
          align: "center",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("discount %"),
          value: "discount",
          align: "center",
          sortable: false,
          width: "15%",
        },
        {
          text: this.$t("total"),
          value: "total",
          align: "center",
          sortable: false,
          width: "15%",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          align: "center",
          sortable: false,
          width: "10%",
        },
      ];
    },
  },
  mounted() {
    const editedUserJSON = localStorage.getItem("user");
    if (editedUserJSON) {
      const editedUser = JSON.parse(editedUserJSON);
      this.$store.commit("users/EDITED_USER", editedUser);
    }
  },

  watch: {
    isVisibleDialog: function (value) {
      if (value == true) {
        this.$store.dispatch("hubs/list");
        this.$store.dispatch("orderStatuses/list");
        this.$store.dispatch("paymentMethods/list");
        this.$store.dispatch("orderOrigins/listNames");
        this.$store.dispatch("orders/types");
        this.$store.dispatch("orders/getOrder", this.editedOrder.id);
      }
    },

    hub_id: function (hub_id) {
      if (!hub_id) {
        return;
      }
      this.$store.dispatch("products/leaf", {
        hub_id,
      });
    },

    search_product_input: function (search) {
      if (this.isLoadingProducts) return;
      if (!search) return;
      this.$store.dispatch("products/leaf", {
        hub_id: this.hub_id,
        itemsPerPage: 20,
        search,
      });
    },

    totalItemsDiscount: function (value) {
      Vue.set(this.editedOrder, "reduction", value.toFixed(2));
    },
  },

  data() {
    return {
      show_created_at_picker: null,
      search_product_input: "",
      is_valid: true,
      formErrors: [],
      orderProductToAdd: {
        qte_in_order: 1,
        discount: 0,
      },
    };
  },

  methods: {
    closeForm() {
      this.$refs.form.reset();
      this.$store.dispatch("orders/closeForm");
      this.formErrors = [];
    },

    async saveForm(order) {
      order.items_total = this.totalItemsPrice;
      order.products = order.orderItems;
      if (this.$refs.form.validate()) {
        try {
          if (order.id) {
            await this.$store.dispatch("orders/update", order);
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
          } else {
            await this.$store.dispatch("orders/add", order);
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
          }
          await this.$store.dispatch("orders/list", {
            page: 1,
            itemsPerPage: 30,
          });
          await this.$store.dispatch("orderOrigins/listNames");
          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          this.formErrors = error.response.data.errors;
        }
      }
    },

    addProduct(orderProduct) {
      if (this.$refs.form.validate()) {
        const oProduct = this.orderProducts.find(
          (item) => item.id === orderProduct.id
        );
        if (oProduct) {
          oProduct.qte_in_order =
            Number(oProduct.qte_in_order) + Number(orderProduct.qte_in_order);
        } else {
          const product = this.listProducts.find(
            (item) => item.id === orderProduct.id
          );
          if (product) {
            if (product.qte_in_hub < 1) {
              this.$store.dispatch(
                "alerts/error",
                "Quantity very low ,quntity zero not accepted !!!! "
              );
            } else {
              orderProduct.name = product.name;
              orderProduct.name_marketing = product.name_marketing;

              if (this.editedOrder.type == "default") {
                orderProduct.order_price_ht = product.price_ht;
                orderProduct.order_tva = product.tva;
                orderProduct.order_price = product.price;
              } else if (this.editedOrder.type == "wholesale") {
                orderProduct.order_price_ht = product.purchase_price_ht;
                orderProduct.order_tva = product.purchase_tva;
                orderProduct.order_price = product.purchase_price;
              }

              orderProduct.price_ht = product.price_ht;
              orderProduct.tva = product.tva;

              orderProduct.purchase_price_ht = product.purchase_price_ht;
              orderProduct.purchase_tva = product.purchase_tva;

              orderProduct.qte_in_hub = product?.qte_in_hub || 0;

              const orderProductsCopy = [...this.orderProducts];
              orderProductsCopy.push(orderProduct);
              this.orderProducts = orderProductsCopy;
            }
          }
        }
        this.orderProductToAdd = {
          qte_in_order: 1,
          discount: 0,
        };
      }
    },

    addProductByEnterKey(event) {
      this.$store
        .dispatch("products/leaf", {
          hub_id: this.hub_id,
          sku: event.target.value,
        })
        .then(() => {
          if (this.listProducts.length == 1) {
            var orderProduct = {
              qte_in_order: 1,
            };
            orderProduct.id = this.listProducts[0].id;
            this.addProduct(orderProduct);
          } else if (this.listProducts.length == 0) {
            this.$store.dispatch("alerts/error", "product not found");
          } else {
            this.$store.dispatch(
              "alerts/error",
              "too many products with this SKU"
            );
          }
          this.search_product_input = "";
        });
    },

    removeProduct(orderProduct) {
      this.orderProducts.splice(
        this.orderProducts.map((item) => item.id).indexOf(orderProduct.id),
        1
      );
    },

    calcProductPrice(order_product) {
      const result =
        this.calcProductPriceHT(order_product) +
        this.calcProductTVA(order_product) -
        this.calcProductDiscount(order_product);
      return Math.round(result * 100) / 100;
    },

    calcProductPriceHT(order_product) {
      return (
        parseFloat(order_product.order_price_ht) * order_product.qte_in_order
      );
    },

    calcProductDiscount(order_product) {
      if (order_product.promotion) {
        const discounted_price =
          Math.round(
            this.calcProductPriceHT(order_product) *
              parseFloat(order_product.promotion.discount)
          ) / 100;

        return order_product.promotion.max_discount_amount
          ? Math.min(
              discounted_price,
              order_product.promotion.max_discount_amount
            )
          : discounted_price;
      }
      return (
        Math.round(
          this.calcProductPriceHT(order_product) *
            parseFloat(order_product.discount)
        ) / 100
      );
    },

    calcProductTVA(order_product) {
      return (
        Math.round(
          (this.calcProductPriceHT(order_product) -
            this.calcProductDiscount(order_product)) *
            parseFloat(order_product.order_tva)
        ) / 100
      );
    },

    getMainHub(listHubs) {
      return listHubs
        .filter((hub) => {
          return hub.is_main == 1;
        })
        .shift();
    },

    getQteInHub(product_id) {
      var originalOrder = this.$store.state.orders.order;
      if (originalOrder?.products_qte) {
        return (
          originalOrder?.products_qte[product_id] +
            originalOrder?.products?.find((item) => item.id == product_id)
              ?.qte_in_order ||
          this.listProducts.find((item) => item.id == product_id)?.qte_in_hub ||
          this.editedOrder.products_qte[product_id] ||
          0
        );
      }
    },

    updateOrderPrice(type) {
      console.log(type);
      if (type == "default") {
        this.orderProducts = this.orderProducts.map(function (item) {
          item.order_price_ht = item.price_ht;
          item.order_tva = item.tva;
          return item;
        });
      } else if (type == "wholesale") {
        this.orderProducts = this.orderProducts.map(function (item) {
          item.order_price_ht = item.purchase_price_ht;
          item.order_tva = item.purchase_tva;
          return item;
        });
      }
    },

    itemBG(item) {
      if (item?.qte_in_hub < item?.qte_in_order) return "red lighten-5";
      return "";
    },
  },
};
</script>
